import './App.css';
import Header from './scripts/header';
import Content from './scripts/content';
import Footer from './scripts/footer';

function App() {
    return ([
        <Header/>,
        <Content/>,
        <Footer/>
    ]);
}

export default App;
