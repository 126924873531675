import logo from '../assets/images/logo.svg';

function Header() {
    return (
        <header className="App-header">
            <img src={logo} className="App-logo" alt="logo"/>
        </header>
    );
}

export default Header;
